const queryparams = {

    pullInfoFromQueryParams: () => {

        const retval = {
            preview: false,
            flexPlayer: false,
            airplay: '0',
            auto_captions: false,
            vscode: ''
        }
        
        let hasParams = false 

        const params = new URLSearchParams(window.location.search)

        for(let key of params.values()) { 
            if (key) {
                hasParams = true
                break
            } 
        }

        // pull vscode, preview and player type from query params
        if (hasParams) {

            retval.vscode = params.get('v')
            if (params.get('preview') === 'true') retval.preview = true
            if (params.get('player_type') === 'flex') retval.flexPlayer = true
            if (params.get('airplay') === '1') retval.airplay = '1'
            if (params.get('auto_captions') === '1') retval.auto_captions = true

        }
        else retval.vscode = window.location.search.slice(1)

        return retval

    }

}

export default queryparams