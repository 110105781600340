import React from 'react'

import './Error.scss'

const Error = (props) => {
	return (
		<div className="error-container">
            <p>We're sorry!</p>
            <p>{props.msg}</p>
            <p>{props.video && props.refreshMsg}</p>
        </div>
	);
};

export default Error;