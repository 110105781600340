export const reducer = (state, action) => {

    switch (action.type) {

        case 'SET_ACCOUNT_ID':
            return {
                ...state,
                accountId: action.payload
            }

        case 'SET_VIDEO_UUID':
            return {
                ...state,
                videoUuid: action.payload
            }

        case 'SET_VIEWER_UUID':
            return {
                ...state,
                viewerUuid: action.payload
            }

        case 'SET_PLAYER_LOADED':
            return {
                ...state,
                playerLoaded: action.payload
            }

        case 'SET_SOCKET':
            return {
                ...state,
                socket: action.payload
            }

        case 'SET_SOCKET_CONNECTION_STATUS':
            return {
                ...state,
                sockConnStatus: action.payload
            }

        case 'SET_PREVIEW_PLAYER':
            return {
                ...state,
                previewPlayer: action.payload
            }

        default:
            return state

    }

}
  
export const initialState = {
    accountId: null,
    videoUuid: null,
    viewerUuid: null,
    playerLoaded: false,
    socket: null,
    sockConnStatus: null,
    previewPlayer: true
}