import { useEffect, useContext } from 'react'
import axios from 'axios'


import { RootContext } from './state/rootContext'
import Player from './components/Player/Player'

import './App.scss'

const App = () => {

    const [state, dispatch] = useContext(RootContext)

    const { videoUuid, accountId, viewerUuid, previewPlayer } = state

    // const openSocket = async () => {

    //     dispatch({ type: 'SET_SOCKET_CONNECTION_STATUS', payload: 'connecting' })

    //     const maxTime = 3000

    //     let connected = false

    //     const startEpoch = Date.now()

    //     while (Date.now() - startEpoch < maxTime && !connected)
    //     {

    //         try 
    //         {
    
    //             const res = await axios.get('https://wsauth01staging.zmapi.net/')

    //             const ws = new WebSocket(res.data.server)

    //             connected = true
    
    //             ws.onopen = () => {
                    
    //                 const obj = {
    //                     event_category: "CONNECTION",
    //                     video_uuid: videoUuid,
    //                     account_id: accountId,
    //                     viewer_uuid: viewerUuid,
    //                     user_agent: window.navigator.userAgent,
    //                     referer: document.referrer
    //                 }
                    
    //                 ws.send(JSON.stringify(obj))
    
    //                 console.log("SENT CONNECTION EVENT:")
    //                 console.log(obj)

    //                 dispatch({ type: 'SET_SOCKET_CONNECTION_STATUS', payload: 'connected' })
            
    //             }
                  
    //             ws.onmessage = evt => console.log(`[message] Data received from server: ${evt.data}`)
                
    //             ws.onclose = evt => {
    //                 if (evt.wasClean) {
    //                     console.log(`[close] Connection closed cleanly, code=${evt.code} reason=${evt.reason}`)
    //                 } else {
    //                     // e.g. server process killed or network down
    //                     // evt.code is usually 1006 in this case
    //                     console.log('[close] Connection died (ws nodejs not running probably)')
    //                 }
    //                 dispatch({ type: 'SET_SOCKET_CONNECTION_STATUS', payload: 'failed' })
    //             }
                
    //             ws.onerror = err => console.log(`[error] ${err.message}`)
    
    //             dispatch({ type: 'SET_SOCKET', payload: ws })

    //             return
    
    //         }
    //         catch (err) { console.log({err}) }

    //     }

    //     dispatch({ type: 'SET_SOCKET_CONNECTION_STATUS', payload: 'failed' })

    // }

    useEffect(() => {
        // if (videoUuid && accountId && viewerUuid && !previewPlayer) openSocket()
    }, [videoUuid, accountId, viewerUuid])

    useEffect(() => {
        
        if (videoUuid && accountId && viewerUuid && !previewPlayer) 
        {
        
          const obj = 
          {
            
            event_category: "CONNECTION",
            video_uuid: videoUuid,
            account_id: accountId,
            viewer_uuid: viewerUuid,
            user_agent: window.navigator.userAgent,
            referer: document.referrer,
            start_epoch: Math.floor(Date.now())
            
          }
          
          let res = axios.post('https://stagingcfvideocollector.zsplayer.workers.dev', obj).then(response => { });

        }

    }, [videoUuid, accountId, viewerUuid])

    return (
        <div className="App">
            <Player />
        </div>
    )

}

export default App
