const applePlatforms = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
]

const ipad13 = navigator.userAgent.includes("Mac") && "ontouchend" in document

const device = {
    
    determineIfTouchDevice: () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0),

    determineIfAppleDevice: el => el.canPlayType('application/vnd.apple.mpegurl') && (applePlatforms.includes(navigator.platform) || ipad13)  

}

export default device