import { useReducer } from 'react'
import { reducer, initialState } from './rootReducer'

import { RootContext } from './rootContext'

export const RootProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <RootContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </RootContext.Provider>
  )
}