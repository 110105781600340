import React from 'react'

import './Loading.scss'

const Loading = () => {
	return (
		<div className="spinner-container">
			<span className="spinner"></span>Loading...
		</div>
	);
};

export default Loading;