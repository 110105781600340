import React from "react";
import PropTypes from "prop-types";

import "./OverlayButton.scss";

const OverlayButton = (props) => {
  const { title, iconUrl, onClick } = props;
  return (
    <div className='overlay'>
      <button className='overlay__btn' onClick={onClick}>
        {iconUrl && (
          <span
            className='overlay__btn__icon'
            style={{ backgroundImage: `url(${iconUrl})` }}
          />
        )}
        {title}
      </button>
    </div>
  );
};

OverlayButton.propTypes = {
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
OverlayButton.defaultProps = {
  iconUrl: undefined,
};
export default OverlayButton;
