import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import Hls from "hls.js";
import axios from "axios";
import plyr from "plyr";
import "plyr/dist/plyr.css";
import { RootContext } from "../../state/rootContext";
import { playerDefaults } from "./playerDefaults";
import useEvent from "../../hooks/useEventHandler";
import time from "../../helpers/time";
import queryparams from "../../helpers/queryparams";
import device from "../../helpers/device";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import OverlayButton from "../OverlayButton/OverlayButton";

import "./Player.scss";
import { ErrorMessages } from "../../constants";
import { genRandomNumber } from "../../helpers/functions";

const INTERVAL = 1000;

const Player = () => {
  const player = useRef(null);
  const hls = useMemo(
    () =>
      new Hls({
        fragLoadingMaxRetry: 3,
        startLevel: -1,
      }),
    []
  );

  const [state, dispatch] = useContext(RootContext);
  const { socket, sockConnStatus, viewerUuid, videoUuid, accountId, previewPlayer } = state;

  const [options, setOptions] = useState(null);
  const [sources, setSources] = useState(null);
  const [showCaptions, setShowCaptions] = useState(false);
  const [secSinceLastSent, setSecSinceLastSent] = useState(0);
  const [loadingError, setLoadingError] = useState(false);
  const [isAllowedDomain, setIsAllowedDomain] = useState(false);
  const [cdnError, setCdnError] = useState(false);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [lastpoch, setLastPoch] = useState(0);
  const [storedTrack, setStoredTrack] = useState(null);

  const isTouchDevice = device.determineIfTouchDevice();

  const fetchManifest = async () => {
    console.log("fetching manifest! staging");

    let res, resOptions, qualityOptions, defaultQuality, captions;

    const { preview, vscode, flexPlayer, airplay, auto_captions } = queryparams.pullInfoFromQueryParams();

    console.log(auto_captions);

    let referrer = "";
    if (document.referrer) {
      let domain = new URL(document.referrer);
      referrer = domain.hostname;
    }

    try {
      res = await axios.post(`https://zsjsonstaging.zmapi.net/check/${vscode}`, { 'referrer': referrer, 'flex': flexPlayer });
    } catch (e) {
      setIsAllowedDomain(true);
      return;
    }

    if (!preview) dispatch({ type: "SET_PREVIEW_PLAYER", payload: false });

    try {

      if (flexPlayer) {

        res = await axios.get(`https://zsjsonstaging.zmapi.net/flex/${vscode}`);

        // check flex active/inactive
        if (res.data?.is_active === "0") {
          setIsActive(false);
          return;
        }

        // parse res
        resOptions = JSON.parse(res.data.default_video_options);
        qualityOptions = resOptions.default_options;
        defaultQuality = parseInt(resOptions.default_quality);

        // must grab captions from videodata record, doesnt exist on flex player records
        const captionsRes = await axios.get(`https://zsjsonstaging.zmapi.net/${resOptions.vscode}`);

        captions = JSON.parse(captionsRes.data.captions_object).map(({ label, lang, captions_file, is_default }) => {
          return {
            kind: "captions",
            label,
            srclang: lang === "en-US" ? "en" : lang,
            src: captions_file,
            ...(is_default === true && { default: true })
          };
        });

        if (captions.length === 1 && !captions[0].default) {
          captions[0].default = true;
        }
      } else {

        // check cdn for assets, if no manifest on cdn, hit lambda endpoint
        if (preview) res = await axios.get(`https://zsjsonstaging.zmapi.net/${vscode}`);
        else {
          // Modified October 4, 2022 to use lambda only to fetch JSON
          // res = await axios.get(`https://assets.playerstaging.zealstream.com/vid/${vscode}.json`);
          // if (!res) res = await axios.get(`https://zsjsonstaging.zmapi.net/${vscode}`);

          res = await axios.get(`https://zsjsonstaging.zmapi.net/${vscode}`);

          // check if a video is active or not
          if (res.data?.is_active === "0") {
            setIsActive(false);
            return;
          }
          else { console.log('in active NOT 0 match'); }
        }

        // parse res
        qualityOptions = res.data.default_options;
        defaultQuality = parseInt(res.data.default_quality);
        captions = JSON.parse(res.data.captions_object).map(({ label, lang, captions_file, is_default }) => {
          return {
            kind: "captions",
            label,
            srclang: lang === "en-US" ? "en" : lang,
            src: captions_file,
            is_default
          };
        });

        if (captions.length === 1 && !captions[0].default) {
          captions[0].default = true;
        }
      }
    } catch (err) {
      setLoadingError(true);
      return;
    }

    const controlObj = {
      airplay,
      ...res.data,
    }
    console.log(controlObj);
    const controlsArr = generateControls(controlObj);

    // set player color
    if (res.data.control_small_play_button_color) handleUpdatePlayerColor({ color: res.data.control_small_play_button_color });
    else handleUpdatePlayerColor({ color: res.data.player_color });

    // unique viewer uuid for each time page is loaded
    const vUuid = uuidv4();
    dispatch({ type: "SET_VIEWER_UUID", payload: vUuid });
    dispatch({ type: "SET_ACCOUNT_ID", payload: res.data.account_id });
    dispatch({ type: "SET_VIDEO_UUID", payload: res.data.uuid });

    setOptions({
      controls: controlsArr,
      quality: {
        default: defaultQuality,
        options: JSON.parse(qualityOptions),
      },
      i18n: playerDefaults.i18n,
      storage: { enabled: false, key: "plyr" },
      muted: res.data.control_autoplay === "1",
      autoplay: res.data.control_autoplay === "1",
      fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
      ...(auto_captions && {
        captions: {
          active: true,
          update: true,
        },
      }),
    });

    setSources({
      type: "video",
      title: "Example Video",
      sources: [
        {
          src: flexPlayer ? resOptions.manifest : res.data.manifest,
          type: "application/x-mpegURL",
          size: defaultQuality,
        },
      ],
      poster: res.data.poster,
      tracks: captions,
    });
  };

  const loadPlayer = async () => {
    console.log("loading player");

    // if reloading the player
    if (player.current && player.current.config) player.current.destroy();
    player.current = new plyr("#plyr", options);

    const isAppleDevice = device.determineIfAppleDevice(player.current.media);

    // some browsers care if you explicitly use the controls attribute and passing them into the html with react
    // (eg, return <video id="plyr" controls/>) doesnt seem to work. so we need to inject them manually here
    // if (!isAppleDevice) player.current.media.setAttribute("controls", true)

    // for apple devices - for some reason this is being caught
    if (isAppleDevice) {
      const source = document.createElement("source");
      source.src = sources.sources[0].src;
      source.type = "application/vnd.apple.mpegURL";
      player.current.media.appendChild(source);
    } else if (Hls.isSupported()) {
      try {
        hls.currentLevel = -1;
        hls.loadSource(sources.sources[0].src);
        hls.attachMedia(player.current.media);

        // after main cdn down, and fail retry 3 times
        hls.on(Hls.Events.LEVEL_SWITCHING, (event, data) => {
          if (data.fragmentError > 3) {
            setCdnError(true);
          }
        });

        // handle hls errors
        hls.on(Hls.Events.ERROR, (evt, err) => {
          // cannot get which fragement getting error with this err
          // have to approximately calculate based on current time
          if (err.details === "bufferStalledError") {
            hls.startLoad(Math.floor(player.current.currentTime / 6) - 1);
          }

          // this error triggers when cannot get the m3u8 master file
          if (err.details === "levelLoadError") {
            const details = {
              url: err.url,
            };

            setErrorDetail(details);
            setCdnError(true);
          }

          // this error triggers when fails to load fragment file
          if (err.details === "fragLoadError") {
            const details = {
              url: err.frag._url,
              level: err.frag.level,
            };

            setErrorDetail(details);
          }

          const { type, fatal } = err;

          console.log("hls error!");
          console.log(type);

          if (fatal) {
            // try to recover network or media error
            switch (type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                break;
            }
          }
        });

        // ! log all hls events
        // Object.keys(Hls.Events).forEach(function (e) {
        //     hls.on(Hls.Events[e], console.info.bind(console))
        // })
      } catch (err) {
        console.log({ err });
      }
    }
    let params = new URLSearchParams(location.search);
    if(params.has("show_poster")){
      if(parseInt(params.get("show_poster")) === 1){
        player.current.media.poster = sources.poster + `?cb=${Math.floor(Math.random() * 100) + 1}`;
      }
    }else{
      player.current.media.poster = sources.poster + `?cb=${Math.floor(Math.random() * 100) + 1}`;
    }


    if (showCaptions) {
      if (sources.tracks) {
        let default_language_code = '';
        for (var i = 0; i < sources.tracks.length; i++) {
            let track = document.createElement("track");
            track.src = checkCaptionUrl(sources.tracks[i].src);
            track.srclang = sources.tracks[i].srclang;
            track.label = sources.tracks[i].label;
            track.kind = "captions";
            track.default = sources.tracks[i].is_default ? true : false;
            track.mode = "showing";
            if (sources.tracks[i].srclang === "en") {
                setStoredTrack(sources.tracks[i]);
            }
            if (sources.tracks[i].is_default) {
                default_language_code = sources.tracks[i].srclang;
            }
            player.current.media.appendChild(track);
        }

        player.current.language = default_language_code;
      }
    }
    window.parent.postMessage({player_loaded:true}, "*");
  };

  const checkCaptionUrl = (captions_file) => {
    let result = '';

    if (captions_file) {
      if (captions_file.startsWith("https://")) {
        result = captions_file;
      }
      else {
        result = 'https://' + captions_file;
      }
    }

    if (previewPlayer) {
      result = result + `?cb=${genRandomNumber(1000,9999)}`;
    }

    return result;
  };

  const setupPlayer = async () => {
    if (!loadingError && options && sources) {
      await loadPlayer();
      dispatch({ type: "SET_PLAYER_LOADED", payload: true });
    }
  };

  const generateControls = ({ control_play_button, control_tracking, control_volume, control_captions, control_fullscreen, airplay }) => {
    const controlsArr = [];
    let params = new URLSearchParams(location.search);
    let no_controls = params.has("controls") && parseInt(params.get("controls")) === 0;
      
    if (parseInt(control_play_button) !== 0 && !no_controls) controlsArr.push("play-large");

    if (parseInt(control_tracking) !== 0 && !no_controls) controlsArr.push("play", "progress", "current-time", "duration", "speed");

    if (parseInt(control_volume) !== 0 && !no_controls) controlsArr.push("volume", "mute");
    if (parseInt(control_captions) !== 0 && !no_controls) {
      setShowCaptions(true);
      controlsArr.push("captions", "settings");
    }

    if (parseInt(control_fullscreen) !== 0 && !no_controls) controlsArr.push("fullscreen");
    if (parseInt(airplay && !no_controls) !== 0) controlsArr.push("airplay");

    return controlsArr;
  };

  const handleIframeEvents = (evt) => {
    switch (evt.data.event) {
      case "controls":
        const { element, state } = evt.data;
        handleToggleControls({ element, state });
        break;

      case "update_player_color":
        const { color } = evt.data;
        handleUpdatePlayerColor({ color });
        break;

      case "set_poster":
        const { poster } = evt.data;
        handleSetPoster({ poster });
        break;

      case "update_caption":
        handleUpdateCaption();
        break;

      default:
        break;
    }
  };

  const handleToggleControls = ({ element, state }) => {
    let newControls;

    // make changes to options
    switch (element) {
      case "volume":
        newControls = generateControls({
          control_play_button: options.controls.indexOf("play-large") > -1 ? 1 : 0,
          control_tracking: options.controls.indexOf("progress") > -1 ? 1 : 0,
          control_volume: state === "on" ? 1 : 0,
          control_captions: options.controls.indexOf("captions") > -1 ? 1 : 0,
          control_fullscreen: options.controls.indexOf("fullscreen") > -1 ? 1 : 0,
        });
        break;

      case "progress":
        newControls = generateControls({
          control_play_button: options.controls.indexOf("play-large") > -1 ? 1 : 0,
          control_tracking: state === "on" ? 1 : 0,
          control_volume: options.controls.indexOf("volume") > -1 ? 1 : 0,
          control_captions: options.controls.indexOf("captions") > -1 ? 1 : 0,
          control_fullscreen: options.controls.indexOf("fullscreen") > -1 ? 1 : 0,
        });
        break;

      case "captions":
        newControls = generateControls({
          control_play_button: options.controls.indexOf("play-large") > -1 ? 1 : 0,
          control_tracking: options.controls.indexOf("progress") > -1 ? 1 : 0,
          control_volume: options.controls.indexOf("volume") > -1 ? 1 : 0,
          control_captions: state === "on" ? 1 : 0,
          control_fullscreen: options.controls.indexOf("fullscreen") > -1 ? 1 : 0,
        });
        break;

      case "fullscreen":
        newControls = generateControls({
          control_play_button: options.controls.indexOf("play-large") > -1 ? 1 : 0,
          control_tracking: options.controls.indexOf("progress") > -1 ? 1 : 0,
          control_volume: options.controls.indexOf("volume") > -1 ? 1 : 0,
          control_captions: options.controls.indexOf("captions") > -1 ? 1 : 0,
          control_fullscreen: state === "on" ? 1 : 0,
        });
        break;

      case "play":
        newControls = generateControls({
          control_play_button: state === "on" ? 1 : 0,
          control_tracking: options.controls.indexOf("progress") > -1 ? 1 : 0,
          control_volume: options.controls.indexOf("volume") > -1 ? 1 : 0,
          control_captions: options.controls.indexOf("captions") > -1 ? 1 : 0,
          control_fullscreen: options.controls.indexOf("fullscreen") > -1 ? 1 : 0,
        });
        break;

      case "autoplay":
        handleAutoplay(state === "on");
        return;

      default:
        return;
    }

    setOptions((options) => ({ ...options, controls: newControls }));
  };

  const handleUpdateCaption = () => {
    setTimeout(()=> { 
      setSources(prev => {
        return { ...prev };
      })
    }, 2000);
  };
  
  /*
  player API Start
  */
  window.addEventListener("message", function (event) {
    player_api(event.data, this.document.getElementById("plyr"));
  })

  const player_api = (data, player) => {
    switch (data.event) {
      case "autoplay":
        player.autoplay = true;
        player.muted = true;
        player.play();
        break;      
      case "play":
        player.play();
        break;
      case "pause":
        player.pause();
        break;
      case "mute":
        player.muted = true;
        break;
      case "unmute":
        player.muted = false;
        break;
      case "get_seconds":
        window.parent.postMessage({get_seconds:true, seconds:player.currentTime }, "*");
        break;
      case "captions":
        // console.log("captions");
        let track = player.textTracks[0];
        if(track){
          track.mode = "showing";
          player.toggleCaptions(true);
        }else{
          // console.log("No Captions");
        } 
        break;
      case "restart" :
        console.log("restart player")
        player.stop();
        player.play();
        break;
      case "disable_context":
        player.disableContextMenu = false;
        break;
      case "set_volume":
        player.volume = data.level ? data.level : 1;
        break;
      case "update_player_color":
        document.documentElement.style.setProperty('--plyr-color-main', data.color);
        break;
      case "controls":
        switch (data.element) {
          case "progress":
            if (data.state === 'on') {
              document.getElementsByClassName('plyr__progress__container')[0].classList.add('hide-it');;
              document.getElementsByClassName('plyr__time--current')[0].classList.add('hide-it');;
            } else {
              document.getElementsByClassName('plyr__progress__container')[0].classList.remove('hide-it');;
              document.getElementsByClassName('plyr__time--current')[0].classList.remove('hide-it');;
            }
            break;
          case "captions":
            if (data.state === 'on') {
              document.querySelector("[data-plyr='captions']").classList.add('hide-it');;
              document.getElementsByClassName('plyr__menu')[0].classList.add('hide-it');;
            } else {
              document.querySelector("[data-plyr='captions']").classList.remove('hide-it');;
              document.getElementsByClassName('plyr__menu')[0].classList.remove('hide-it');;
            }
            break;
          case "volume":
            if (data.state === 'on') {
              document.getElementsByClassName('plyr__volume')[0].classList.add('hide-it');;
            } else {
              document.getElementsByClassName('plyr__volume')[0].classList.remove('hide-it');;
            }
            break;
          case "play":
            if (data.state === 'on') {
              document.querySelector("[data-plyr='play']").classList.add('hide-it');;
            } else {
              document.querySelector("[data-plyr='play']").classList.remove('hide-it');;
            }
            break;
          case "fullscreen":
            if (data.state === 'on') {
              document.querySelector("[data-plyr='pip']").classList.add('hide-it');;
              document.querySelector("[data-plyr='fullscreen']").classList.add('hide-it');;
            } else {
              document.querySelector("[data-plyr='pip']").classList.remove('hide-it');;
              document.querySelector("[data-plyr='fullscreen']").classList.remove('hide-it');;
            }
            break;
        }
        break;
    }
  }

/*
player API END
*/

  const handleUpdatePlayerColor = ({ color }) => {
    if (color) {
      document.querySelector(":root").style.setProperty("--plyr-color-main", color);
    }
  };

  const togglePlaying = () =>{
    player.current.togglePlay();
  }

  const handleSetPoster = ({ poster }) => {
    setSources({
      ...sources,
      poster: poster + `?cb=${Math.floor(Math.random() * 100) + 1}`,
    });
  };

  const handleAutoplay = (state = true) => {
    if (player.current) {
      player.current.autoplay = state;
      player.current.muted = state;
      player.current.togglePlay(state);
      setOptions((optionsState) => ({ ...optionsState, autoplay: state, muted: state }));
    }
  };

  const handleUnmute = (state = true) => {
    if (player.current) {
      player.current.muted = !state;
     
      setOptions((optionsState) => ({ ...optionsState, muted: !state }));
      player.current.togglePlay(state);
      console.log('calling unmute')
    }
  };

  const sendWatchDataToServer = () => {

    const nowpoch = time.msSinceEpoch();
    const timeline = Math.floor(player.current.currentTime);
    let interval = 10000;

    if(lastpoch === 0) { interval = 10000;  }
    else
    {

      interval = nowpoch - lastpoch;

    }
    
    setLastPoch(nowpoch);

    let obj = 
    {
    
      event_category: "VIEWER_USAGE",
      uuid: viewerUuid,
      video_uuid: videoUuid,
      viewer_uuid: viewerUuid,
      account_id: accountId,
      event_id: "1",
      start_epoch: nowpoch - interval,
      last_current_epoch: nowpoch,
      interval: interval,
      user_agent: window.navigator.userAgent,
      referer: document.referrer,
      timeline
    
    };

    // 11/6/2022
    // send to cloudflare worker
    setSecSinceLastSent(0)
    let res = axios.post('https://stagingcfvideocollector.zsplayer.workers.dev', obj).then(response => { });
    
  }

  const handleMobileOverlayClicked = () => {
    if (player.current){
      togglePlaying();
    } 
  };

  // bind window.addEventListener w/ cleanup each render
  useEvent("message", (evt) => handleIframeEvents(evt));

  // fetch manifest + player settings and set the options and sources state
  useEffect(() => fetchManifest(), []);

  // after manifest, captions and other player settings are loaded, load the player
  //TODO:  ASK why this is in useEffect and not just loadd once after manifest. Maybe b/c it needs to be done before render, but why not just Await.
  useEffect(() => setupPlayer(), [options, sources, loadingError]);

  useEffect(() => {
    if (!previewPlayer && (secSinceLastSent >= 10) && player.current && player.current.playing) sendWatchDataToServer()
  }, [secSinceLastSent, sockConnStatus, previewPlayer]);

  useEffect(() => {
    
    if (secSinceLastSent < 120) 
    {

      const interval = setInterval(() => setSecSinceLastSent(secSinceLastSent + 1), INTERVAL)
      return () => clearInterval(interval)

    } 
    else 
    {

      const interval = setInterval(() => setSecSinceLastSent(0), INTERVAL)
      return () => clearInterval(interval)

    }


  }, [secSinceLastSent, sockConnStatus]);

  useEffect(() => {
    let checkPlayerInterval;

    checkPlayerInterval = setInterval(() => {
      let vid = document.getElementById("plyr");

      if (vid && storedTrack) {
        vid.addEventListener(
          "webkitbeginfullscreen",
          function (evt) {
            console.log("going into fullscreen");
            console.log(storedTrack);
            document.documentElement.style.setProperty(
              "--webkit-text-track-display",
              "block"
            );
            storedTrack.mode = "showing";
            let track = document.createElement("track");
            track.src = storedTrack.src;
            track.srclang = storedTrack.srclang;
            track.label = storedTrack.label;
            track.default = storedTrack.is_default ? true : false
            track.kind = "captions";
            track.mode = "showing";
            vid.appendChild(track);
          },
          false
        );

        vid.addEventListener(
          "webkitendfullscreen",
          function (evt) {
            console.log("exiting fullscreen");
            document.documentElement.style.setProperty(
              "--webkit-text-track-display",
              "none"
            );
          },
          false
        );

        clearInterval(checkPlayerInterval);
      }
    }, 1000);

    return () => {
      clearInterval(checkPlayerInterval);
    };
  }, [storedTrack]);

  useEffect(() => {
    // send request to the failed URL (ts or m3u8) every 60 sec
    async function checkCdnConnection() {
      const mainUrl = errorDetail.url;
      const res = await axios.get(mainUrl);
      return res.status;
    }

    let myInterval;
    if (cdnError && errorDetail) {
      myInterval = setInterval(() => {
        checkCdnConnection().then((result) => {
          if (result === 200) {
            for (let i = 0; i < hls.levels.length; i++) {
              hls.levels[i].urlId = 0;
            }

            setCdnError(false);
            setErrorDetail(null);
          }
        });
      }, 60000);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [cdnError, errorDetail]);

  if (!isActive) return <Error msg={ErrorMessages.NOT_ACTIVE_MSG} />;
  if (isAllowedDomain) return <Error msg={ErrorMessages.DOMAIN_MSG} />;
  if (loadingError) return <Error video msg={ErrorMessages.VIDEO_MSG} refreshMsg={ErrorMessages.REFRESH_MSG} />;
  if (options && sources)
    return (
      <>
        <video ref={player} id="plyr" crossOrigin="true" playsInline allowfullscreen="true" muted={options.muted} autoPlay={options.autoplay} />
        {options?.muted && player?.current?.muted && <OverlayButton title="Click to unmute" iconUrl="/images/mute.svg" onClick={handleUnmute} />}
        {/* this mobile overlay allows single press gestures to pause the player on mobile */}
{ options.controls.indexOf("progress") < 0 && options?.muted === false && player?.current?.muted === false && <div id="mobileOverlay" className={isTouchDevice ? '' : 'd-none'} onClick={handleMobileOverlayClicked} /> }
{  }
{/* <div id="mobileOverlay" className={isTouchDevice ? '' : 'd-none'} onClick={handleMobileOverlayClicked} /> */ }
      </>
    );
  else return <Loading />;
};

export default Player;